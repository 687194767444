@import "@ractf/ui-kit/Colours.scss";


.hr {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    flex-basis: 100%;

    &::after {
        display: block;
        content: "";
        margin-left: 10%;
        background-color: var(--col-back-lift);
        width: 80%;
        height: 1px;
    }
}

.textBlock {
    font-size: px(16);
    background-color: var(--col-darkGrey);
    padding: px(16) px(32);
    border-radius: px(8);
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.5;
    width: 100%;
    margin-bottom: px(8);

    & img {
        max-width: 100%;
        padding: px(1);
        display: block;
    }

    @media only screen and (max-width: 600px) {
        & {
            margin: px(24) px(8);
        }
    }
}

.pageHead {
    padding: 0 16px;
    border-bottom: 2px solid var(--col-back-lift);
    margin-bottom: 1rem !important;
    margin-top: px(24);

    p, a {
        font-size: .95rem;
        margin: 0 !important;
    }
    > :last-child {
        margin-bottom: px(8) !important;
    }

    .pageTitle {
        margin-right: 16px;
        font-family: $titleStack;
        font-weight: 600;
        font-size: 1.5945rem;
    }
}

.subtleText {
    opacity: 0.8;
    font-size: 0.9em;
    margin-bottom: px(16);
}


.large {
    font-size: 1.15em;
    line-height: 1.2;

    b {
        font-weight: 800;
    }
}

.siteWrap {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.loadingSpinner {
    border: .25rem solid var(--col-accent);
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: text-bottom;
    border-radius: 50%;
    border-bottom-color: transparent;

    animation: spin .75s linear infinite;

    @each $type in $type-names {
        &.#{$type} {
            border-color: var(--type-#{$type}-bg);
        }
    }
}
.modalSpinner {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.wordmark {
    background: url("~../../../static/wordmark.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 120px;
    height: 33px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
