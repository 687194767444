@import "@ractf/ui-kit/Colours.scss";

.holder {
    position: fixed;
    top: px(60);
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.countdown {
    margin-bottom: px(4);
    background-color: var(--col-back-lift);
    padding: 0.8rem 4rem 0.8rem 2rem;
    font-family: $monoStack;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: px(4) 0 0 px(4);
    cursor: pointer;
    transition: padding 200ms ease, border-radius 200ms ease, background-color 200ms ease;
    z-index: 50;
    box-shadow: 0 1px 5px #000;

    &:hover {
        background-color: var(--col-back-lift-2x);
    }

    @include mapTypes();

    &.closed {
        line-height: 0;
        padding: 0.8rem 1.5rem 0.8rem 1rem;
        border-radius: 10rem 0 0 10rem;
    }
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}

@media (max-width: 800px) {
    .countdown {
        top: px(6);
        padding-right: 3rem;
        box-shadow: 0 1px 3px #000;
    }
}

.countdown {
    top: px(54) !important;
}
