@import "@ractf/ui-kit/Colours.scss";

$bar: (
    width: 4px,
    track: var(--col-back-lift),
    handle: var(--col-back-lift-2x),
);
$primary: (
    width: 8px,
    track: var(--col-back-lift),
    handle: var(--col-accent),
);

:global(.simplebar-content) {
    &::before {
        display: none !important;
    }
}
:global(.simplebar-track) {
    width: map-get($bar, "width") !important;
    margin: 4px;

    &::after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: map-get($bar, "width") !important;
        background-color: map-get($bar, "track");
    }
    @media (max-width: 800px) {
        display: none;
    }
}
:global(.simplebar-scrollbar)::before {
    background: map-get($bar, "handle") !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    border-radius: map-get($bar, "width") !important;
}
:global(.simplebar-visible)::before {
    opacity: 1 !important;
}
:global(.simplebar-visible) ~ * {
    background-color: #f0f;
    border: 2px solid #f00;
}
.primary {
    > :global(.simplebar-track) {
        background-color: map-get($primary, "track");
        width: map-get($primary, "width") !important;
        border-radius: map-get($primary, "width") !important;

        > :global(.simplebar-scrollbar)::before {
            background: map-get($primary, "handle") !important;
            border-radius: map-get($primary, "width") !important;
        }
    }
}
