// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

.errorImg {
    max-width: 80vw;
    width: 500px;
    margin: 32px auto 64px;
}

.errorWrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    &>div:first-child {
        font-size: 7em;
        font-weight: 500;
    }
    &>div:nth-child(2) {
        font-size: 1.5em;
        font-weight: 500;
    }
    &>*:nth-child(3) {
        margin-top: 16px;
        font-size: 1.2em;
        font-weight: 400;
    }
}
