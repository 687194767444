@import "@ractf/ui-kit/Colours.scss";

$pb_background: var(--col-back-lift);
$pb_fill: var(--col-accent);
$pb_done: var(--col-green);
$pb_over: var(--col-red);

.progressBar {
    height: 8px;
    border-radius: 4px;
    background-color: $pb_background;
    margin-top: px(4);
    margin-bottom: px(8);
    width: 100%;
    position: relative;
    flex-grow: 1;

    &.thick {
        height: 20px;
    }

    > .pbInner {
        background-color: $pb_fill;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        border-radius: 4px;
    }

    &.pbDone > .pbInner {
        background-color: $pb_done;
    }
    &.pbOver > .pbInner {
        background-color: $pb_over;
    }
}
