@import "@ractf/ui-kit/Colours.scss";

.navGap {
    flex-grow: 1;
}

.navItem {
    padding: 0.5rem;
    display: block;
}

.navItem > a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: underline;
    }
}


.navMenu {
    position: relative;
    @each $type in $type-names {
        &.#{$type} {
            color: var(--type-#{$type}-fg);
        }
    }

    .navItem > a::after {
        content: "";
        margin-left: 0.3em;
        vertical-align: 0.15em;
        display: inline-block;
        height: 0;
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }

    .navMenuDropdown {
        position: fixed;
        top: 100%;
        z-index: 1000;
        left: 0;
        top: 0;
        cursor: pointer;

        min-width: 10rem;
        padding: 0.5rem 0;
        font-size: 1rem;
        border-radius: 2px;

        background-color: var(--col-background);
        color: var(--col-color);
        border: 1px solid var(--col-back-lift);

        .navItem > a {
            color: inherit;

            &::after {
                vertical-align: middle;
                margin-left: 0.5em;

                border-left: 0.3em solid;
                border-top: 0.3em solid transparent;
                border-right: 0;
                border-bottom: 0.3em solid transparent;
            }
        }

        a,
        .navItem {
            display: block;
            width: 100%;
            line-height: 1.5;
            padding: 0.25rem 1.5rem;

            text-decoration: none;

            &:hover,
            &:focus,
            &:focus-within {
                background-color: var(--col-back-lift);
            }
        }

    }
}

.navCollapse, .navBar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navCollapse {
    flex-grow: 1;
    transition: height 200ms ease;
}

.navBar {
    padding: 0.5rem 1rem;
    width: 100%;
    font-size: 0.93rem;
    flex-shrink: 0;

    background-color: var(--col-back-lift);
    color: var(--col-color);

    @include mapTypes();
}

.navBurgerIcon {
    display: none;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;

    .burgerStripe {
        position: absolute;
        height: 2px;
        background-color: var(--col-color);
        width: 80%;
        flex-shrink: 0;
        opacity: 1;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        &:nth-of-type(1) {
            top: 30%;
        }
        &:nth-of-type(3) {
            top: 70%;
        }
        transition-property: opacity, transform, left, top;
        transition-duration: 100ms;
        transition-timing-function: ease;
    }

    &.navCrossIcon {
        .burgerStripe:nth-of-type(1) {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        .burgerStripe:nth-of-type(2) {
            opacity: 0;
        }
        .burgerStripe:nth-of-type(3) {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &:hover, &:focus {
        background-color: var(--col-back-lift);
    }
}

@each $type in $type-names {
    &.#{$type} {
        background-color: var(--type-#{$type}-fg);
    }
}

.navBrand {
    font-family: $titleStack;
    margin-right: 1rem;
    font-size: 1.25rem;
    padding: 0.3125rem 0;
    color: inherit;

    .navItem > a {
        margin: 0;
        padding: 0;
    }
    a {
        color: inherit;
    }
}

@media (min-width: 1001px) {
    .navCollapse {
        height: auto !important;
    }
}
@media (max-width: 1000px) {
    .navCollapse {
        overflow-y: hidden;
        flex-basis: 100%;
        display: block;
        //margin: 0 -3rem 0 -1rem;
        will-change: height;

        & > * {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        .navGap:not(:last-child) {
            margin: .5rem 0;
            height: 1px;
            background-color: var(--col-back-lift);
        }
    }
    .navBurgerIcon {
        display: flex;
    }
    .navItem {
        margin: 0;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: var(--col-back-lift);
        }
    }
    .navMenu {
        padding-bottom: 0;
        position: initial !important;

        .navMenuDropdown .navItem > a, .navItem > a {
            position: relative;
            padding-bottom: .25em;
            font-weight: 800;
            &::after {
                content: "";
                display: block;
                margin: 0;
                position: absolute;
                height: 1px;
                left: -1rem;
                right: -1rem;
                top: -.5rem;
                background-color: var(--col-back-lift);
                border: none;
            }
        }

        .navMenuDropdown {
            visibility: visible !important;
            position: unset !important;
            background-color: transparent;
            color: unset;
            border: none;
            padding: 0;
            > * {
                width: auto;
                color: unset !important;
                margin: 0;
                line-height: unset !important;
                padding: 0.5rem 1rem !important;
            }
        }
    }
}
