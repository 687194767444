@import "@ractf/ui-kit/Utils.scss";

$fontStack: 'Lato', sans-serif;

$titleStack: 'Montserrat', sans-serif;
$monoStack: 'Roboto Mono', monospace;

// Background shades
$back_200: #7f7b99;
$back_600: #37344c;
$back_700: #272538;

// Light shades
$white_100: #dfddeb;

// @import "@ractf/ui-kit/Theme.scss";

$input: (
    border: 1px solid var(--col-back-lift),
    border_focus: 1px solid var(--col-back-lift-2x),
    border_error: 1px solid var(--col-red),
    border_error_focus: 1px solid var(--col-red),

    background: rgba(0, 0, 0, .2),
    background_disabled: transparent,

    color: var(--col-color),
    color_error: var(--col-red),
    color_disabled: var(--col-darkGrey),

    padding: px(9.5) px(14),
    tabsPadding: px(2.5) px(14),
    font_size: px(14),
    line_height: px(16),
    radius: px(8),
    min_height: px(36),
);

$type-names: [
    "primary", "secondary", "success", "info", "warning", "danger", "dark", "light",
    "sidebarType", "typeCustom1", "typeCustom2", "typeCustom3", "typeCustom4"
];

// @import "@ractf/ui-kit/Theme.scss";

:export {
    bg_l5: desaturate(lighten(#000, 56%), 12%);
    monoStack: $monoStack;
}

// @import "@ractf/ui-kit/Theme.scss";
