@import "@ractf/ui-kit/Colours.scss";

.tableWrap {
    width: 100%;
    overflow-x: auto;
    margin-bottom: px(16);

    table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;

        th,
        td {
            vertical-align: top;
            border-top: 1px solid var(--col-back-lift);
            margin: 0;
            padding: 0;

            a,
            span {
                padding: 0.75rem;
                color: inherit;
                display: block;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }

            vertical-align: top;
        }
        th.sortable {
            position: relative;
            cursor: pointer;
            padding-right: 1.5rem;

            &::before,
            &::after {
                border: 4px solid transparent;
                content: "";
                display: block;
                height: 0;
                right: 0.75rem;
                top: 50%;
                position: absolute;
                width: 0;
            }
            &::before {
                border-bottom-color: rgba(var(--col-color), 0.35);
                margin-top: -9px;
            }
            &::after {
                border-top-color: rgba(var(--col-color), 0.35);
                margin-top: 1px;
            }

            &:hover::before {
                border-bottom-color: rgba(var(--col-color), 0.65);
            }
            &:hover::after {
                border-top-color: rgba(var(--col-color), 0.65);
            }
        }

        tr {
            td {
                background-color: var(--col-background);
                color: var(--col-color);
            }
            &:hover > td {
                background-color: var(--col-back-lift);
            }

            @include mapTypes(true, "> td");
        }
    }
}
