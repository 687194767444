@import "@ractf/ui-kit/Colours.scss";

.badge {
    display: inline-block;
    vertical-align: middle;
    align-self: center;

    background-color: var(--col-back-lift);
    color: var(--col-color);

    padding: px(6) px(12);
    line-height: 1;
    white-space: nowrap;
    font-size: px(12);
    border-radius: px(4);
    font-weight: 700;

    &.outline {
        border: 2px solid;
        padding: px(4) px(10);
        border-color: unset !important;
    }

    &.pill {
        padding-right: 0.8em;
        padding-left: 0.8em;
        border-radius: 10rem;
    }

    &.x {
        padding-right: px(28);
        position: relative;

        & > div:first-child {
            position: absolute;
            height: px(14);
            width: px(14);
            right: px(7);
            border-radius: px(7);
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--col-back-lift);
            cursor: pointer;

            &:hover {
                background-color: var(--col-back-lift-2x);
            }

            &::after,
            &::before {
                content: "";
                position: absolute;
                width: px(8);
                display: block;
                height: px(2);
                left: px(7);
                top: px(7);
                transform: translate(-50%, -50%) rotate(45deg);
                background-color: var(--col-color);
            }
            &::before {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    @include mapTypes();
}
