// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

@function px($size) {
    @return ($size / 16) * 1rem;
}

@mixin mapPrimary($bg, $fg) {
    background-color: $bg;
    border-color: transparent;
    color: $fg;
}
@mixin mapPrimaryHover($bg, $fg) {
    @include mapPrimary(hover($bg), $fg);
}
@mixin mapPrimaryFocus($bg, $fg) {
    @include mapPrimary(focus($bg), $fg);
}
@mixin mapLesser($bg, $fg) {
    background-color: transparent;
    border-color: $bg;
    color: $fg;
}
@mixin mapLesserHover($bg, $fg) {
    @include mapPrimaryHover($bg, $fg);
}
@mixin mapLesserFocus($bg, $fg) {
    @include mapPrimaryFocus($bg, $fg);
}

@mixin mapTypes($interactive: false, $selector: "", $forceBorder: false, $doLesser: true) {
    @each $type in $type-names {
        &.#{$type} #{$selector} {
            @include mapPrimary(var(--type-#{$type}-bg), var(--type-#{$type}-fg));
            @if ($forceBorder) {
                border-color: var(--type-#{$type}-bg);
            }
        }
        @if ($doLesser) {
            &.#{$type}.lesser #{$selector} {
                @include mapLesser(
                    var(--type-#{$type}-bg-lesser, var(--type-#{$type}-bg)),
                    var(--type-#{$type}-fg-lesser, var(--type-#{$type}-bg))
                );
            }
        }
        @if ($interactive) {
            &.#{$type}.lesser:not(.disabled)#{$selector}:hover,
            &.#{$type}.lesser:not(.disabled)#{$selector}:focus,
            &.#{$type}.lesser:not(.disabled)#{$selector}:active {
                @include mapPrimary(var(--type-#{$type}-bg), var(--type-#{$type}-fg));
            }
        }
    }
}
