@import "@ractf/ui-kit/Colours.scss";

.grid {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom: px(16);

    td {
        padding: px(4) px(8);
        word-wrap: break-word;
        width: 100%;
    }
    td:not(:last-child) {
        border-right: 2px solid var(--col-back-lift);
    }
    tr:not(:last-child) {
        border-bottom: 2px solid var(--col-back-lift);
    }
    thead {
        border-bottom: 4px solid var(--col-back-lift);
        font-weight: 700;
    }

    &.colPair {
        table-layout: auto;

        td:first-child {
            text-align: right;
        }
        td:last-child {
            text-align: left;
        }
    }
}
