// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

.medalOuterWrap {
    position: relative;
    overflow: hidden;
}
.medalWrap {
    padding: 24px 32px;
    font-size: .9em;
    &>div:first-child {
        font-size: 1.3em;
        margin-bottom: 12px;
    }
    z-index: 2;
    position: relative;
}
.medalIcon {
    position: absolute;
    right: -16px;
    bottom: -16px;
    height: 96px;
    width: 96px;
    opacity: .6;
    &>svg {
        height: 96px;
        width: 96px;
    }
}
.basePopupWrap {
    padding: 24px 32px;
    font-size: .9em;
    &>div:first-child {
        font-size: 1.3em;
        margin-bottom: 12px;
    }
}
