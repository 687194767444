// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

@import "@ractf/ui-kit/Colours.scss";


.memberTheme {
    display: flex;
    text-align: left;
    font-size: 1.2em;
    padding: 2px 16px;

    &>*:last-child {
        flex-grow: 1;
        width: 100%;
        word-break: break-all;
    }
    &>* {
        flex-shrink: 0;
    }
}

.memberIcon {
    font-size: 1.5em;
    margin-right: 12px;
    opacity: .2;

    &.clickable {
        cursor: pointer;
        transition: 200ms ease opacity;

        &:hover, &:focus {
            opacity: 1;
        }
    }

    &.bad {
        color: var(--col-red);
    }
    &.active {
        opacity: 1;
        cursor: default;
        color: var(--col-green);
    }
}
