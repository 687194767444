@import "@ractf/ui-kit/Colours.scss";

.leader {
    background-color: var(--col-back-lift);
    padding: px(16) px(24);
    border-radius: 2px;
    width: 100%;
    cursor: pointer;
    transition: 150ms background-color ease;
    display: block;
    color: var(--col-color);
    margin-bottom: px(8);

    &.leaderGreen {
        color: var(--col-white);
        background-color: var(--col-green);
        .leaderSub {
            color: var(--col-white);
            opacity: .9;
        }
    }

    .leaderName {
        font-weight: 600;
        font-family: $titleStack;
    }
    .leaderSub {
        color: var(--col-color);
        opacity: .7;
        font-size: 0.9em;
    }

    position: relative;
    &:not(.leaderX):not(.leaderNone) {
        &::after {
            transition: 150ms border-color ease;
            content: "";
            display: block;
            border: 4px solid;
            border-left: 0;
            border-top: 0;
            transform: translate(-50%, -50%) rotate(-45deg);
            position: absolute;
            top: 50%;
            right: 12px;
            width: 10px;
            height: 10px;
        }
    }
    &.leaderX {
        &::after, &::before {
            transition: 150ms background-color ease;
            content: "";
            display: block;
            width: 4px;
            background-color: var(--col-color);
            position: absolute;
            top: 50%;
            height: 20px;
            right: 20px;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    &:hover,
    &:focus {
        background-color: var(--col-accent);
        &.leaderX {
            &::after, &::before {
                background-color: var(--col-background);
            }
        }
        text-decoration: none;
        color: var(--col-background);

        .leaderSub {
            color: var(--col-background);
        }
    }
}
