// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

@import "@ractf/ui-kit/Colours.scss";

.pfp {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: #0004;
    border: none;
    margin-right: px(16);
}
.profileBanner {
    h2 {
        margin-bottom: 0;
    }
}
.profileBack {
    background-color: inherit;
    top: -32px;
    left: -64px;
    right: -64px;
    bottom: 0;
    z-index: -1;
    position: absolute;

    @media (max-width: 1200px) {
        top: -16px;
        left: -32px;
        right: -32px;
    }
    @media (max-width: 800px) {
        left: -4px;
        right: -4px;
        top: -4px;
    }
}
.profileRow {
    padding-top: px(32);
    padding-bottom: px(24);
    margin-bottom: px(24) !important;
    display: flex;
    @media (max-width: 576px) {
        display: block;
    }
}
.profileSep {
    &::before {
        content: "\00b7";
    }
    margin: 0 px(4);
}
.socialsRow > * {
    color: inherit;
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
    display: flex;
    align-items: center;
    margin-right: px(16) !important;
    svg {
        margin-right: px(4);
    }
}

.userName {
    font-size: 2em;
    font-weight: 600;
    font-family: $titleStack;
    word-wrap: break-word;

    svg {
        margin-bottom: -0.125em;
    }
}
.teamMemberico {
    color: var(--col-color);

    svg {
        margin-bottom: -0.125em;
        margin-right: 0.25em;
    }
}
.userBio {
    font-size: 0.8em;
    margin-bottom: 1em;
    &.noBio {
        font-style: italic;
    }
}
.userSocial {
    display: flex;
    align-items: center;
    margin: 8px 0;
    color: var(--col-color);
    transition: 150ms color ease;
    opacity: 0.8;
    cursor: pointer;

    *:first-child {
        margin-right: 8px;
        font-size: 1.2em;
    }
    *:last-child {
        font-size: 0.9em;
    }
    &:hover {
        color: var(--col-color);
        text-decoration: none;
        opacity: 1;
    }
}

.noSolves {
    font-style: italic;
    text-align: left;
    font-size: 0.9em;
    margin-top: 0.6em;
}

.userSolve {
    padding: 12px 16px;
    background-color: var(--col-back-lift);
    margin-bottom: 4px;
    width: 100%;
    text-align: left;

    & > div:first-child {
        margin-bottom: 4px;
    }
    & > div:last-child {
        font-size: 0.8em;
    }
}

@media (max-width: 1200px) {
    .profileSplit {
        flex-direction: column;
        align-items: center;

        & > :first-child {
            margin-bottom: 24px;
            margin-right: 0;
            width: 100%;
            min-width: 0;
            max-width: 100%;
        }

        & > * {
            width: 100%;
        }
    }
}
