@import "@ractf/ui-kit/Colours.scss";

.slide {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 56px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--col-back-lift);
    transition: 200ms all ease;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        left: 2px;
        top: 2px;
        border-radius: 10px;
        background-color: var(--col-back-lift);

        transition: 200ms all ease;
    }
    &.on {
        &::before {
            left: 34px;
            background-color: #291888;
        }
        background-color: #ff2f87;
    }
}
