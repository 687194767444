// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

@import "@ractf/ui-kit/Colours.scss";

.lockWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    flex-grow: 1;
    padding: 32px;
    text-align: center;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;

    .lockTitle {
        font-family: $titleStack;
        font-size: 3em;
        font-weight: 600;
    }

    .siteCountdown {
        margin-top: .5em;
        font-size: 1.5em;
        font-weight: 400;
    }

    .tooltip {
        margin-top: .5em;
        font-style: italic;
        transition: opacity 2s ease;
    }

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: -1;
    }
}
