@import "@ractf/ui-kit/Colours.scss";

.itemStack {
    display: flex;
    flex-direction: column;

    > .item {
        padding: px(12) px(16);
        user-select: none;
        cursor: pointer;

        position: relative;
        line-height: 1;
        text-decoration: none;

        &:not(:first-child)::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: px(1);
            background: var(--col-back-lift);
        }

        &::after {
            content: "";
            display: none;
            background-color: var(--col-back-lift);
            opacity: .2;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        &:hover,
        &.active {
            &::after {
                display: block;
            }
        }

        > .label {
            float: right;
            margin-top: px(-4);
            margin-bottom: px(-4);
        }

        @include mapTypes($selector: "> .label");
        @each $type in $type-names {
            &.#{$type} {
                color: var(--type-#{$type}-bg);

                &::after {
                    background-color: var(--type-#{$type}-bg);
                    opacity: .1;
                }
            }
        }
    }
}
