@import "@ractf/ui-kit/Colours.scss";

.checkbox {
    display: flex;
    align-items: center;
    text-align: left;
    margin: .2em 0 .2em .4em;
    cursor: pointer;

    .box {
        width: 1.1em;
        height: 1.1em;
        background-color: var(--col-back-lift);
        border-radius: 2px;
        flex-shrink: 0;
        margin-right: .4em;
        position: relative;
    }
    .cbLabel {
        flex-grow: 1;
        font-size: .9em;
        opacity: .8;
    }

    &:hover .box {
        background-color: var(--col-back-lift-2x);
    }
    &.checked {
        .box {
            background-color: var(--col-accent);

            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 43%;
                left: 50%;
                width: 25%;
                height: 55%;
                border: 2px solid var(--col-white);
                border-top: 0;
                border-left: 0;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }
}
