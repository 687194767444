@import "@ractf/ui-kit/Colours.scss";


$edge_colour: var(--col-back-lift);

.tabRow {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;

    outline: none;

    .tabButton {
        display: block;
        padding: 9px 24px;
        text-decoration: none;
        color: var(--col-color);
        outline: none;
        cursor: pointer;

        &.active {
            border-top-color: $edge_colour;
            border-left-color: $edge_colour;
            border-right-color: $edge_colour;
        }
        &:not(.active) {
            border-bottom-color: $edge_colour;
        }

        border: 1px solid transparent;
        margin-right: -1px;

        &:hover, &:focus {
            border-top-color: $edge_colour;
            border-left-color: $edge_colour;
            border-right-color: $edge_colour;
        }
    }
    .tabTrailer {
        border-bottom: 1px solid $edge_colour;
        flex-grow: 1;
    }
}
