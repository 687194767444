@import "@ractf/ui-kit/Colours.scss";

$text: var(--col-white);
$sidebar_accent: var(--col-accent);

.sidebar {
    width: 250px;
    flex-shrink: 0;
    background-color: #1e1c34;

    :global(.simplebar-content) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
.sidebarInner {
    padding: px(20);
}
.item {
    padding: px(8);
    font-size: px(14);
    color: var(--col-color);
    border-radius: px(4);
    margin: px(4) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1em;
    transition: 100ms ease background-color;

    &.active {
        background-color: var(--col-accent);
    }
    &:not(.active):hover,
    &:not(.active):focus {
        background-color: var(--col-back-lift);
    }
    > svg {
        width: px(18);
        height: px(18);
        margin: px(-3) px(-15) px(-3) px(-3);
    }
    > span {
        margin-left: px(21);
    }
}
:focus > .item:not(.active) {
    background-color: var(--col-back-lift);
}

.head {
    padding: px(8);
    margin-bottom: px(20);

    & > *:first-child {
        width: 150px;
        height: 42px;
        display: block;
        margin: auto;
    }
}
.header {
    cursor: pointer;
    user-select: none;
    font-size: px(11);
    color: #9d9da3;
    margin: px(16) px(-4) 0 px(-4);
    padding: px(8);
    border-radius: px(4);
    font-weight: 600;
    position: relative;

    svg {
        position: absolute;
        right: px(4);
        top: 50%;
        transform: translateY(-50%);
        transition: transform 200ms ease-in;
    }

    &.active svg {
        transform: translateY(-50%) rotate(-90deg);
    }

    &:hover,
    &:focus {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.body {
    flex-grow: 1;
    width: 100%;
    padding: 32px 64px;
    position: relative;
    margin-right: 0;
    transition: margin-right 200ms ease;
}

.foot {
    background-color: rgba(0, 0, 0, 0.1);
    padding: px(20) px(25);
    font-size: px(12);
    footer {
        display: flex;
        align-items: center;
        margin-bottom: px(8);

        p {
            opacity: 0.6;
        }

        img {
            margin-right: 8px;
            width: px(16);
        }
    }

    span,
    a {
        opacity: 0.9;
        color: inherit;
        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.skip {
    flex-grow: 1;
}

.headerBar {
    width: 100%;
    height: 60px;
    background: var(--col-back-lift);
    padding: 16px 32px;
    z-index: 50;
    display: none;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    @include mapTypes();
    box-shadow: 0 -1px 5px #000;

    position: fixed;
    left: 0;
    top: 0;

    & > a {
        font-size: 1.4em;
        font-weight: 500;
        color: $text !important;
        flex-basis: 0;
        flex-grow: 1;
    }
    & svg {
        height: 38px;
    }
}

.headerPad {
    display: none;
    height: 60px;
    flex-shrink: 0;
}

@media (max-width: 800px) {
    .headerBar {
        display: flex;
    }

    .headerPad {
        display: block;
    }
}

.children {
    overflow-y: hidden;
    transition: height 200ms ease;
}
.isClosed > div:not(:focus-within) {
    height: 0 !important;
    min-height: 0;
}

.burgerUnderlay {
    width: 220px;
    flex-shrink: 0;
    display: none;
}

.burger {
    display: none;
}

@media (max-width: 1200px) {
    .body {
        padding: 16px 32px;
    }
}

@media (max-width: 800px) {
    .body {
        padding: 4px;
    }

    .burgerUnderlay {
        width: auto;
    }

    .wrap > .sidebar {
        width: 300px;
        transition: left 200ms ease;
        position: fixed;
        left: -300px;
        top: 0px;
        z-index: 100;
        font-size: 1.1em;
        overflow-y: auto;
        height: 100%;
        display: flex;

        .sidebarInner {
            width: 300px;
        }
    }
    .burger {
        display: block;
        position: fixed;
        left: 0px;
        top: 0px;
        font-size: 32px;
        z-index: 50;
        color: #fff;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .open {
        & > .sidebar {
            left: 0;
        }

        .burgerUnderlay {
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100%;
            background: #0005;
            z-index: 50;
        }
    }
}
