@import "@ractf/ui-kit/Colours.scss";


.inputErrorWrap {
    flex-grow: 1;
}

.inputError {
    color: map-get($input, "color_error");
    font-size: .8rem;
    margin-left: 8px;
    margin-top: .125rem;
}
