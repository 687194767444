@import "@ractf/ui-kit/Colours.scss";


.select {
    user-select: none;
    text-align: left;
    font-size: map-get($input, "font_size");
    line-height: map-get($input, "line_height");
    flex-grow: 1;

    &.mini {
        display: inline-block;

        .head, .items div {
            @include mapLesser(var(--type-primary-bg), var(--type-primary-fg-lesser, var(--type-primary-bg)));
            &:hover,
            &:focus,
            &.sOpen {
                @include mapLesserHover(var(--type-primary-bg), var(--type-primary-fg));
            }
            &:active {
                @include mapLesserFocus(var(--type-primary-bg), var(--type-primary-fg));
            }

            font-size: px(14);
            line-height: px(16);
            min-width: unset;
            padding: px(6) px(12);
        }
    }
    &.pill {
        .head {
            border-radius: px(14);
            &.sOpen {
                border-radius: px(14) px(14) 0 0;
            }
        }
        .items div:last-child {
            border-radius: 0 0 px(14) px(14);
        }
    }

    .blanker {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    select {
        display: none;
    }

    .head {
        border-radius: map-get($input, "radius");
        position: relative;
        &.sOpen {
            border-radius: map-get($input, "radius") map-get($input, "radius") 0 0;
            background-color: var(--col-back-lift);
        }

        &::after {
            position: absolute;
            content: "";
            top: calc(50% - #{px(2)});
            right: px(12);
            width: px(3);
            height: px(3);
            border: px(2) solid;
            transform: translate(-50%, -50%) rotate(-45deg);
            border-right-color: transparent;
            border-top-color: transparent;
        }
    }

    .items div,
    .head {
        min-width: 100px;
        padding: map-get($input, "padding");

        cursor: pointer;

        border: map-get($input, "border");

        background: map-get($input, "background");
        color: map-get($input, "color");

        &:hover {
            background-color: var(--col-back-lift);
        }
    }
    .items div {
        border-top: none;

        &:last-child {
            border-radius: 0 0 map-get($input, "radius") map-get($input, "radius");
        }
    }

    .search {
        border-radius: 0;
        border: map-get($input, "border");
        background-color: var(--col-background);
    }

    .itemsWrap {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        z-index: 10;
    }

    .items {
        overflow-y: auto;
        background-color: var(--col-background);
        overflow-y: auto;
        box-shadow: 0 2px 7px -3px #000;
    }
}
