@import "@ractf/ui-kit/Colours.scss";

.footer {
    background-color: var(--col-black);
    color: var(--col-white);
    font-size: .9rem;
    width: 100%;

    @include mapTypes();
}

.footRow {
    @include mapTypes();

    padding: px(16);
    line-height: 1.5;

    .footRowInner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1000px;
        margin: auto;
        @media (max-width: 800px) {
            justify-content: center;
            text-align: center;
        }
    }

    img {
        margin-bottom: auto;
        margin-right: px(32);
        margin-left: px(16);
        @media (max-width: 800px) {
            width: 100%;
            flex-basis: 100%;
            text-align: center;
            margin-bottom: px(24);
        }
    }

    &.center .footRowInner {
        justify-content: center;
        text-align: center;
    }
    &.right .footRowInner {
        justify-content: flex-end;
        text-align: right;
    }
    &.column .footRowInner {
        flex-direction: column;
    }
    &.main {
        padding: px(60) px(80) px(36);
        @media (max-width: 800px) {
            padding: px(24) px(16) px(37);
        }
    }
    &.slim {
        font-size: px(12);
    }
    &.darken {
        background-color: rgba(0, 0, 0, 0.2);
        color: rgba(255, 255, 255, 0.6);
    }

    p {
        margin-bottom: 0;
    }
}

.footCol {
    line-height: 1.25;
    margin: 0 px(48);
    @media (max-width: 800px) {
        margin: 0 px(16);
    }
    .footColTitle {
        font-weight: 600;
        margin-bottom: px(6);
        font-size: inherit;
    }
    a {
        opacity: 0.7;
        margin: px(2) 0 0;
        display: block;
        &:hover {
            opacity: 1;
        }
    }

    > * {
        color: inherit;
        text-decoration: none;

        &:hover, &:focus {
            color: inherit;
        }
    }
}
