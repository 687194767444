@import "@ractf/ui-kit/Colours.scss";

.btn {
    font-size: px(14);
    border-radius: px(8);
    padding: px(11) px(21);
    font-weight: 700;
    line-height: px(16);

    border: none;
    display: inline-block;
    cursor: pointer;
    text-decoration: none !important;
    transition: background-color 200ms, color 200ms;
    user-select: none;
    outline: none;
    position: relative;

    &.fullWidth {
        width: 100%;
    }
    &.large {
        padding: px(14) px(27);
        font-size: px(16);
    }
    &.tiny {
        padding: px(6) px(12);
    }
    &.pill {
        border-radius: 10rem;
    }

    @include mapTypes(true);
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        transition: opacity 200ms;
        background-color: #000;
        opacity: 0;
        border-radius: inherit;
    }
    &:hover::before,
    &:focus::before {
        opacity: 0.1;
    }
    &:active::before {
        opacity: 0.3;
    }
    > span {
        z-index: 1;
        position: relative;
    }

    &.lesser {
        border: 1px solid;
        padding: calc(#{px(11)} - 1px) px(21);

        &.large {
            padding: calc(#{px(14)} - 1px) px(27);
        }
        &.tiny {
            padding: calc(#{px(6)} - 1px) px(12);
        }
    }

    &.disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }

    &:hover {
        text-decoration: none !important;
    }

    &.hasIcon {
        padding-left: px(57);
        &.tiny {
            padding-left: px(40);
        }
    }
    &.onlyIcon {
        height: px(36);
        padding: 0 !important;
        > .icon {
            position: initial;
            border-radius: px(4);
        }
        &.pill > .icon {
            border-radius: 10rem;
        }
        &.tiny {
            height: px(28);
        }
    }

    > .icon {
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        width: px(40);
        height: 100%;
        border-radius: px(4) 0 0 px(4);
        padding: px(8) px(11);
        position: absolute;
    }
    &.tiny > .icon {
        padding: px(8);
        width: px(28);
    }
    &.pill > .icon {
        border-radius: 10rem 0 0 10rem;
    }

    &:hover {
        > .tooltip {
            display: block;
        }
    }
    > .tooltip {
        font-weight: 400;
        display: none;
        position: absolute;
        background-color: inherit;
        left: 50%;
        margin-top: px(8);
        top: 100%;
        padding: px(11);
        z-index: 1;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 3px #0005;
        border-radius: px(4);
        transform: translateX(-50%);
        min-width: px(100);
        text-align: center;

        &::before {
            display: block;
            content: "";
            left: 50%;
            position: absolute;
            top: px(-6.5);
            transform: translateX(-50%) rotate(45deg);
            border-radius: inherit;
            width: px(8);
            height: px(8);
            z-index: -10;
            background-color: inherit;
            border: inherit;
            border-right-width: 0;
            border-bottom-width: 0;
        }
    }
}
