@import "@ractf/ui-kit/Colours.scss";


$link: (
    color: #4582ec,
    hover: #1559cf
);

/* --- Root elements --- */
* {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
}
html {
    width: 100%;
    height: 100%;
}
body {
    font-family: $fontStack;
    background-color: var(--col-background);
    color: var(--col-color);
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 1rem;
}

/* --- Typography --- */
p {
    margin: 0;
    margin-bottom: 1em;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &:last-child {
        margin-bottom: 0;
    }
}
small {
    font-size: .8em;
}
h1, h2, h3, h4, h5, h6 {
    font-family: $titleStack;
    margin-top: 0;
    margin-bottom: px(16);
    font-weight: 600;
    line-height: 1.2;
}
h4, h5, h6 {
    margin-bottom: px(8);
}
h1 {
    font-size: 2.6575rem;
}
h2 {
    font-size: 2.126rem;
}
h3 {
    font-size: 1.86025rem;
}
h4 {
    font-size: 1.5945rem;
}
h5 {
    font-size: 1.32875rem;
}
h6 {
    font-size: 1.063rem;
}

/* --- Typography effects */
code {
    text-align: left;
    background-color: var(--col-back-lift);
    font-family: $monoStack;
    padding: .2em .4em;
    margin: 0 2px;
    font-size: 85%;
    border-radius: 8px;
}
pre > code {
    display: block;
    padding: 16px;
    border-radius: 3px;
    line-height: 1.45;
    overflow: auto;
}
b {
    font-weight: 600;
}
pre:first-child {
    margin-top: 0;
}
pre:last-child {
    margin-bottom: 0;
}

/* --- Lists --- */

ul {
    text-align: left;
}
ul > li {
    margin: 16px 0;
}

/* --- Misc --- */

center {
    width: 100%;
}

a, .linkStyle {
    text-decoration: none;
    color: map-get($link, "color");
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:hover, &:focus {
        color: map-get($link, "hover");
        text-decoration: none;
        outline: none;
    }
}
