@import "@ractf/ui-kit/Colours.scss";

:global(.debug) {
    .container {
        outline: 1px solid #f00;
        background-color: #f001;
    }
    .containerRow {
        outline: 1px solid #0f0;
        background-color: #0f01;
    }
    .flexRow {
        outline: 1px solid #00f;
        background-color: #00f1;
    }
    .flexColumn {
        outline: 1px solid #ff0;
        background-color: #ff01;
    }
}

.container {
    width: 100%;
    padding-right: px(16);
    padding-left: px(16);
    margin-right: auto;
    margin-left: auto;

    &.right {
        text-align: right;
    }
    &.spaced:not(:last-child) {
        margin-bottom: px(8);
    }

    & > :last-child {
        margin-bottom: 0;
    }

    &.toolbar {
        display: flex;
        flex-wrap: wrap;
        margin-right: px(-8);
        margin-bottom: px(-8);
        &.spaced {
            margin-bottom: 0;
        }
        width: auto;
        > * {
            margin-right: px(8);
            margin-bottom: px(8);
        }
        > .container.toolbar {
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    &.full {
        margin-left: px(-16);
        margin-right: px(-16);
        width: calc(100% + #{px(32)});
    }

    &.centre {
        text-align: center;
        justify-content: center;
    }

    &:not(.full) {
        @media (min-width: 576px) {
            max-width: 540px;
        }
        @media (min-width: 768px) {
            max-width: 720px;
        }
        @media (min-width: 992px) {
            max-width: 960px;
        }
        @media (min-width: 1200px) {
            max-width: 1140px;
        }
    }
}
.containerRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: px(-16);
    margin-left: px(-16);
    margin-top: px(-16);
    padding-bottom: 0;
}
.containerCol {
    position: relative;
    width: 100%;
    padding-right: px(16);
    padding-left: px(16);
}
.containerForm {
    > *:not(:first-child) {
        margin-top: px(8);
    }
    &:not(:last-child) {
        margin-bottom: px(16);
    }
}

.flexRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    max-width: 100%;
    flex-grow: 1;

    &.frRight {
        justify-content: flex-end;
    }
    &.frLeft {
        justify-content: flex-start;
    }
    &.centre {
        justify-content: center;
    }
    &.vCentre {
        align-items: center;
    }

    & > * {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
    &.frTight {
        margin-right: -0.25rem;
        margin-bottom: -0.25rem;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        > * {
            margin-right: 0.25rem;
            margin-bottom: 0.25rem;
        }
    }

    > .flexRow {
        margin-right: 0;
        margin-bottom: 0;
    }
}

@mixin colBlock($name, $size) {
    @for $i from 1 through 12 {
        @if $size {
            @media (min-width: #{$size}) {
                &.#{$name}#{$i} {
                    max-width: (100% / 12) * $i;
                }
            }
        } @else {
            &.#{$name}#{$i} {
                max-width: (100% / 12) * $i;
            }
        }
    }
}

.flexColumn {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    margin: 0;
    padding: px(16) px(16) 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    &.centre {
        justify-content: center;
        align-self: stretch;
    }

    &.noGutter {
        padding: 0;
    }

    > .flexRow {
        flex-grow: 0;
    }

    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
        &.container.toolbar {
            margin-bottom: px(-8);
        }
    }

    > .container {
        margin-left: px(-16);
        margin-right: px(-16);
        width: calc(100% + #{px(32)});
    }

    @include colBlock("col", 0);
    @include colBlock("col-sm", 576px);
    @include colBlock("col-md", 768px);
    @include colBlock("col-lg", 992px);
    @include colBlock("col-xl", 1200px);
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-gap: 2rem;
    width: 100%;

    > * {
        height: 100%;
    }
}

.masonry {
    display: flex;
    flex-wrap: wrap;
    margin-right: px(-16);
    > * {
        flex-grow: 1;
        flex-shrink: 1;
        width: calc(100% - #{px(16)});
        flex-basis: px(250);
        margin-bottom: px(16);
        margin-right: px(16);
    }
}
