@import "@ractf/ui-kit/Colours.scss";

.spoiler {
    width: 100%;

    > .title {
        padding-left: px(16);
        position: relative;
        user-select: none;
        cursor: pointer;
        width: 100%;
        margin: px(8) 0;

        > svg {
            position: absolute;
            top: 50%;
            width: px(16);
            height: px(16);
            left: 0;
            transform: translateY(-50%);
            transition: transform 200ms ease-in;
        }
    }
    > .body {
        opacity: 0;
        padding: 0;
        margin-left: -px(16);
        overflow-y: hidden;
        transition: height 200ms, margin 200ms, opacity 200ms ease-in;

        > div {
            overflow: auto;
        }
    }
    &:not(.open) > .body {
        height: 0 !important;
    }
    &.open {
        > .title > svg {
            transform: translateY(-50%) rotate(90deg);
        }
        > .body {
            opacity: 1;
            margin-left: px(8);
        }
    }
}
