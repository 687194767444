@import "@ractf/ui-kit/Colours.scss";

.card {
    border-radius: px(8);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: var(--col-back-lift);
    color: var(--col-color);
    margin-bottom: px(16);

    &.lesser {
        border: px(1) solid var(--col-back-lift);
        background: transparent;
        &.slim {
            .body {
                padding: 0 px(23) px(15);
            }
            .head {
                padding: px(15) px(23) 0;
            }
        }

        .body {
            padding: 0 px(23) px(23);
        }
        .head {
            padding: px(23) px(23) 0;
        }
        .collapse {
            margin: px(-1) px(-1) 0;
            padding: px(9) px(9) px(8);
            width: calc(100% + #{px(2)});
        }

        @include mapTypes(true, "> .collapse", false, false);
    }
    &.slim {
        .body {
            padding: 0 px(24) px(16);
        }
        .head {
            padding: px(16) px(24) 0;
        }
    }

    @include mapTypes();

    &.noPad {
        padding: 0;

        .body {
            margin-top: 0;
        }
    }

    .collapse {
        background-color: var(--col-back-lift);
        border-radius: px(8) px(8) 0 0;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: px(8);
        user-select: none;
        transition: border-radius 200ms, width 200ms, background-color 200ms;
        cursor: pointer;

        svg {
            flex-shrink: 0;
            transition: transform 200ms ease-in;
            margin-left: px(4);
            margin-right: px(12);
        }
        span {
            flex-grow: 1;
            line-height: 1;
            transition: opacity 200ms ease-in;
            opacity: 0;
        }

        &.closed {
            border-radius: px(8);
            svg {
                transform: rotate(-90deg);
            }
            span {
                opacity: 1;
            }
        }
    }

    .head {
        padding: px(24) px(24) 0;

        line-height: 1;
        font-family: $titleStack;
        display: flex;
        .headMain {
            flex-grow: 1;
            margin-right: px(16);
        }
        .header {
            font-size: px(16);
            font-weight: 600;
        }
        .subtitle {
            margin-top: px(4);
            display: flex;
            align-items: center;
            font-size: px(14);
            font-weight: 500;
        }
    }
    .spacer {
        height: px(8);
    }
    .body {
        padding: 0 px(24) px(24);
        overflow: hidden;
    }
    &.lesser .body {
        color: var(--col-color);
    }

    .shrinker {
        transition: height 200ms, opacity 200ms ease-in, min-height 200ms;
    }

    &.isClosed .shrinker {
        height: 0 !important;
        min-height: 0;
        opacity: 0;
    }

    &.warning {
        .body {
            color: var(--type-warning-fg);
        }
    }
}

.old {
    .card {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--col-back-lift) !important;
        border-radius: 0.25rem;
        overflow: hidden;
        margin-bottom: px(24);

        @include mapTypes();

        > .cardHeader {
            padding: px(10) px(16);
            margin-bottom: 0;
            background-color: rgba(0, 0, 0, 0.03);
            border-bottom: 1px solid var(--col-back-lift);
            position: relative;

            > svg {
                position: absolute;
                top: 50%;
                width: px(16);
                height: px(16);
                left: px(8);
                transform: translateY(-50%) rotate(90deg);
                transition: transform 200ms ease-in;
            }
        }
        > .cardBody {
            flex: 1 1 auto;
            min-height: 1px;
            > div {
                padding: px(16);
            }
            margin-top: 0;
            transition: height 200ms, margin-top 200ms, opacity 200ms ease-in, min-height 200ms;
            opacity: 1;
        }
        &.collapsible {
            > .cardHeader {
                cursor: pointer;
                user-select: none;
                padding-left: px(32);
            }
        }

        &.isClosed {
            > .cardBody {
                height: 0 !important;
                min-height: 0;
                opacity: 0;
                margin-top: -1px;
            }

            > .cardHeader > svg {
                transform: translateY(-50%) rotate(0);
            }
        }

        &.framed {
            background-color: transparent;
            color: inherit;

            @include mapTypes(false, "> .cardHeader", true);
        }
        &.noPad > .cardBody > div {
            padding: 0;
        }
    }
}
