// Copyright (C) 2020 Really Awesome Technology Ltd
//
// This file is part of RACTF.
//
// RACTF is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published
// by the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// RACTF is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with RACTF.  If not, see <https://www.gnu.org/licenses/>.

@import "@ractf/ui-kit/Colours.scss";

.notif {
    background-color: var(--type-primary-bg);
    color: var(--type-primary-fg);
    border-radius: px(4);
    padding: px(12) px(16);
    box-shadow: 0 0 8px #0005;
    margin: 8px;
    cursor: pointer;
    transition: 200ms ease opacity;
    opacity: 1;

    &:hover {
        opacity: .8;
    }

    .notifTitle {
        font-weight: 700;
    }
    .notifBody {
        float: left;
    }
    .notifTime {
        float: right;
        opacity: .5;
        font-size: 0.6em;
        margin-top: px(2);
    }
}

.announcementsWrap {
    position: fixed;
    top: 8px;
    right: 8px;
    display: flex;
    max-height: 80vh;
    overflow-y: hidden;
    z-index: 200;
    max-width: calc(100% - 16px);
    width: 450px;
    flex-direction: column-reverse;

    @media (max-width: 800px) {
        width: 100%;
    }
}
